<template>
  <div class="spacer-2x"></div>
  <AppSection :title="contact.section_title" id="contact">
    <div class="col-12 mb-4">
      <div v-html="contact.v_html" />
      <contact-form :text="contact.button.text" />
    </div>
  </AppSection>
</template>

<script>
import contact from '@/config/texts/home/contact';
import AppSection from '@/components/app/Section';
import ContactForm from '@/components/contact/Form';

export default {
  name: 'Contact',
  components: {
    AppSection,
    ContactForm,
  },
  data() {
    return {
      isLoaded: false,
      contact
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: $sp-2x;
}
</style>
