<template>
  <div class="col-12 col-lg-7 mb-4">
    <h3 class="txt-primary mb-4 txt-mob-center">{{ summary.section_title }}</h3>
    <div v-html="summary.v_html" />
    <div class="btn-div">
      <router-link :to="summary.button.router_link">
        <button class="app-btn mt-4">
          {{ summary.button.text }}
        </button></router-link
      >
    </div>
  </div>
  <div class="col-12 col-lg-5 about-img mb-4 active">
    <div class="img-bg active"></div>
    <img class="img-fluid" :src="imgUrl" alt="Alison Vandromme Photo" />
  </div>
</template>

<script>
import Api from '@/utils/services/Api';
import summary from '@/config/texts/about/summary';

export default {
  name: 'InShort',
  data() {
    return {
      summary,
      imgUrl: '',
    };
  },
  created() {
    this.imgUrl = Api.getUrl('images', 'avandromme.jpg');
  },
};
</script>
