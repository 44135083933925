<template>
  <div class="row home-header">
    <div class="col-12">
      <p class="txt-primary mb-0 slide-down-d0">{{  header.overtitle }}</p>
      <h1 class="slide-down-d0">{{  header.h1 }}</h1>
      <h2 class="txt-muted slide-left-d0">{{  header.h2 }}</h2>
      <p class="description slide-left-d0">
        {{  header.p }}
      </p>
      <router-link :to="header.button.router_link" class="app-btn slide-left-d0">{{ header.button.text }}</router-link>
    </div>
  </div>
</template>

<script>
import header from '@/config/texts/home/header';

export default {
  name: 'HomeHeader',
  data() {
    return {
      header,
    }
  }
};
</script>

<style lang="scss">
.home-header {
  min-height: 60vh !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    margin-bottom: $sp;
  }
  .description {
    max-width: 60%;
    margin-bottom: $sp-2x;
  }
  .app-btn {
    font-size: 20px !important;
  }
}

@include bp-down(lg) {
  .home-header {
    text-align: center;
    padding-bottom: $sp-2x;
    .description {
      max-width: 100%;
    }
  }
}
</style>
