export default {
  section_title: 'Summary',
  v_html: `<p>In my collaborations with experts, partners and clients I have been praised as a professional who sets clear goals, is consistent in her decision-making and knows how to delegate respectfully and persuasively. </p>
  This aside, I keep a clear head under pressure, and move forward in projects and challenges with a self-assured critical stance. I guide projects with an open mind, sound strategy, and flexible approach. I keep an open mindset to solve challenges. To me, work ethics are an essential guideline throughout my endeavors, which I define as being self-disciplined, managing my time and that of my team well, being a reliable presence and self-driven to motivate others. </p>
  <p>In my work ethic I never surrender critical thinking for just executing what the client wants. 
  Rather, I focus on what the client needs. My background in psychological disciplines helps me understand someone's deeper motivations and I find this helpful in developing a project with confidence,
  persuasion, flexibility and a strong sense of curiosity. 
  </p>`,
  img: {
    alt: 'Alison Vandromme - Photo'
  },
  button: {
    router_link: {
      name: 'Projects'
    },
    text: 'See my work'
  }
};
