<template>
  <app-section
    title="About me"
    id="about"
    @activated="isActive = true"
    @deactivated="isActive = false"
  >
    <div class="row mb-4 about fade-in-long">
      <div class="col-12 col-lg-7 mb-4">
        <div v-html="about.v_html"/>
        <div class="btn-div">
          <router-link :to="about.button.router_link">
            <button class="app-btn mt-4">{{ about.button.text }}</button></router-link
          >
        </div>
      </div>
      <div class="col-12 col-lg-5 about-img mb-4" :class="{ active: isActive }">
        <div class="img-bg" :class="{ active: isActive }"></div>
        <img class="img-fluid" :src="imgUrl" :alt="about.img.alt" />
      </div>
    </div>
  </app-section>
</template>

<script>
import Api from '@/utils/services/Api';
import about from '@/config/texts/home/about';
import AppSection from '@/components/app/Section';

export default {
  name: 'HomeAbout',
  components: {
    AppSection,
  },
  data() {
    return {
      about,
      isActive: false,
      imgUrl: ''
    };
  },
  created() {
    this.imgUrl = Api.getUrl('images', 'avandromme.jpg')
  }
};
</script>
