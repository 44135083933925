export default {
  overtitle: 'Hi, my name is',
  h1: 'Alison Vandromme.',
  h2: 'I build things for the web.',
  p: 'As a former Chief Technical Officer, Tech Lead, and Software Engineer, I worked on many projects for various clients. Each project significantly differed from one another, allowing me to broaden my knowledge and skills. However, they all had in common the focus on building accessible and inclusive products conceived in a user-first mindset. My latest projects aimed to make life cycle assessment and environmental issues accessible to a broader spectrum. In keeping with this theme, I take an eco-design approach to web services conception.',
  button: {
    router_link: {
        name: 'Projects'
    },
    text: 'See my work',
  },
};
