export default {
    page_title: 'About me',
    section_title: 'In short',
    v_html: `<p>
    My name is Alison, and I enjoy creating things that live on the
    internet.<br />
  </p>
  <p>
  Passionate about crafting robust, user-centric web solutions, I bring a wealth of experience as a full-stack web developer and former tech leader, demonstrating proficiency in various technologies and a knack for translating complex requirements into functional designs.
  </p>
  <p>
  In my collaborations with experts, partners and clients I have been praised as a professional
  who keeps a clear head under pressure and who moves forward in projects with humility, an
  open mind, a strong focus on the strategy and an open mindset to solve challenges. In my work 
  ethic, I never surrender critical thinking for just executing what the client wants. Rather, I focus
  on what the client needs. </p>`,
  button: {
    router_link: {
        name: 'About'
    },
    text: 'Get to know me'
  },
  img: {
    alt: 'Alison Vandromme - Photo'
  },
}