<template>
  <div class="row">
    <div class="col-12 text-center">
      <h2>Other recent projects</h2>
      <router-link :to="{ name: 'Projects' }" class="hovered-link"
        >View all projects</router-link
      >
    </div>
  </div>
  <transition-group
    class="row mt-4"
    name="staggered-fade"
    tag="div"
    @before-enter="beforeEnter"
    @enter="enter"
    @afterEnter="afterEnter"
    @leave="leave"
    @afterLeave="afterLeave"
  >
    <div
      class="col-12 col-lg-6 col-xl-4 mt-4 project-card"
      v-for="(project, index) in pagedProjects"
      :key="project.id"
      :data-index="index"
      :class="project.id"
    >
      <project-card :project="project" :index="index" />
    </div>
  </transition-group>
  <div class="row mt-4 mb-4" id="projects-btn">
    <div class="col-12 btn-center" v-if="showMore">
      <button class="app-btn" @click="loadProjects">Show more</button>
    </div>
    <div class="col-12 btn-center show-all" v-else>
      <button class="sec-btn" @click="showLess">Show less</button>
      <router-link :to="{ name: 'Projects' }" class="app-btn"
        >View all</router-link
      >
    </div>
  </div>
</template>

<script>
import ProjectCard from '@/components/projects/ProjectCard';
import Api from '@/utils/services/Api';

export default {
  name: 'Projects',
  components: {
    ProjectCard,
  },
  data() {
    return {
      page: 1,
      displayed: 3,
      pageSize: 3,
      showMore: true,
      moreText: 'Show more',
      projects: []
    };
  },
  computed: {
    pagedProjects() {
      return this.projects.slice(0, this.displayed);
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      const { projects } = await Api.getAll({
        category: 'home'
      });
      this.projects = projects
    },
    loadProjects() {
      this.page++;
      this.displayed += this.pageSize;
      if (this.displayed === this.projects.length)
        this.showMore = false;
    },
    showLess() {
      this.page = 1;
      this.displayed = 3;
      this.showMore = true;
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      let delay;
      const index = +el.dataset.index;
      if (index <= 6 && index >= 3) delay = (index - 3) * 0.3;
      if (index <= 9 && index >= 6) delay = (index - 6) * 0.3;
      setTimeout(() => {
        el.style.opacity = 1;
        done();
      }, delay * 1000);
    },
    afterEnter(el) {
      let element;
      if (el.dataset.index === '5') {
        element = document.querySelector(`.${this.projects[4].id}`);
        this.scrollTo(element, 0.1, 'center');
      }

      if (el.dataset.index === '8') {
        element = document.querySelector(`.${this.projects[7].id}`);
        this.scrollTo(element, 0.1, 'center');
      }
    },
    leave(el, done) {
      let delay;
      const index = +el.dataset.index;

      if (index === 8) delay = 0.2;
      if (index === 7) delay = 0.4;
      if (index === 6) delay = 0.6;
      if (index === 5) delay = 0.8;
      if (index === 4) delay = 1;
      if (index === 3) delay = 1.2;

      setTimeout(() => {
        el.style.opacity = 0;
        done();
      }, delay * 1000);
    },
    afterLeave(el) {
      if (el.dataset.index === '8') {
        const element = document.querySelector(`.${this.projects[1].id}`);
        this.scrollTo(element, 1.2, 'center');
      }
    },
    scrollTo(el, delay, position) {
      setTimeout(() => {
        el.scrollIntoView({ behavior: 'smooth', block: position });
      }, delay * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.project-card {
  transition: opacity 0.5s $trs-default;
}

.show-all {
  button {
  
  margin-right: 0.5rem;
  }
  a {
    margin-left: 0.5rem;
  }
}
</style>